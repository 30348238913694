import React, { useEffect, useState } from "react";

import { Button, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { getUserLeadsCount, removeLeads, storeFeatureUsage } from "../../api/axios";
import "../../style/Collaborators.css";


export default function UnassignLeads(props) {

    const { setToast, setConfirmation, color, collaborators, auth } = props;

    const [userId, setUserId] = useState("__undefined__");
    const [userLeadsCount, setUserLeadsCount] = useState(null);

    function updateUserLeadsCount(userId) {
        getUserLeadsCount(userId).then((res) => {
            setUserLeadsCount(res.data);
        });
    }

    useEffect(() => {
        if (userId === "__undefined__") {
            return;
        }

        setUserLeadsCount(null);
        updateUserLeadsCount(userId);
    }, [userId]);

    const removeAllLeadsFromCollaborator = () => {
        if (userId === "__undefined__") {
            return;
        }

        setConfirmation({
            "text": "Etes-vous sur de vouloir retirer tous les leads de cet utilisateur ?",
            "showYesButton": true,
            "showNoButton": true,
            "specialPopup": null,
            "confirmCallback": () => {
                removeLeads(userId).then(() => {
                    setToast({ text: "Leads retirés.", severity: "success" });
                    updateUserLeadsCount(userId);
                    storeFeatureUsage(auth.id, "admin_unassign_leads_from_collaborator",
                        JSON.stringify({ userLeadsCount, userId: userId }));
                }).catch((err) => {
                    console.error(err);
                    setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
                });
            }
        });
    }

    const customButtonStyle = {
        backgroundColor: color,
        color: 'white',
    };

    function getCollaboratorInfo() {
        if (userId === "__undefined__") {
            return null;
        }

        const collaborateurRows = collaborators.filter(colab => colab.id === userId);
        if (collaborateurRows.length === 0) {
            return null;
        }

        return collaborateurRows[0];
    }

    const userInfo = getCollaboratorInfo();

    function getCollabdisplayString(collab) {
        let prefix = collab.office ? `[${collab.office.toUpperCase()}] ` : "";
        return `${prefix}${collab.lastname.toUpperCase()} ${collab.firstname}`;
    }

    return (
        <>
            <h3 className="title" style={{ color }}>Retirer des leads</h3>

            <Grid item xs={12} key="select_worker">
                <InputLabel>Sélectionnez un collaborateur:</InputLabel>
                <Select value={userId} onChange={ev => setUserId(ev.target.value)}>
                    <MenuItem value="__undefined__">Veuillez choisir</MenuItem>;
                    {collaborators.map((collab) => {
                        return <MenuItem key={collab.id} value={collab.id}>{getCollabdisplayString(collab)}</MenuItem>;
                    })}
                </Select>
                <br />
            </Grid>

            {userLeadsCount !== null && userInfo !== null ? <Grid item xs={12} key="user_lead_count">
                <p>{userInfo.firstname} a {userLeadsCount} leads.</p>
                <br />
            </Grid> : null}

            {userId !== "__undefined__" ? <Grid item xs={12} key="select_count_leads_to_unassign">
                <Button onClick={removeAllLeadsFromCollaborator} variant="contained" style={customButtonStyle}>
                    Retirer tous les leads (toutes listes confondues)
                </Button>
            </Grid> : null}
        </>
    );
}
