import React, { useContext, useRef, useState, useEffect } from "react"
import Header from "./component/header";
import { useNavigate } from "react-router-dom";
import { getBackendHost } from './lib/config';
import AuthContext from "./context/AuthProvider";
import axios from 'axios';
import Cookies from 'js-cookie';

import "./style/Login.css"
import Toast from "./component/ux/toast";


export default function Login() {
    const nav = useNavigate()
    const { auth, setAuth, color, secondaryColor } = useContext(AuthContext);
    const [success, setSuccess] = useState(false);
    const [values, setValues] = useState({ email: "", password: "" });
    const [toast, setToast] = useState(null);

    // devenu useless et pleins d'autres elements associes aussi
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');

    const URL = `${getBackendHost()}/v1/la/`;

    const axiosInstance = axios.create({
        baseURL: URL
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { email, password } = values
            const response = await axiosInstance.post('login',
                JSON.stringify({ email, password }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response) {
                if (response.data.is_active === true) {
                    setAuth(response.data);
                    localStorage.setItem("user", JSON.stringify(response?.data))
                    Cookies.set('auth', response?.data.id);
                    setSuccess(true);
                    nav('/');
                    console.log(auth);
                } else {
                    setAuth({});
                    setToast({ text: "Votre compte a été désactivé.", severity: "error" });
                }
            }
        } catch (err) {
            setToast({ text: "Erreur de connection, veuillez réessayer puis vérifier votre mot de passe.", severity: "error" });
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    const updateEmail = (newEmail) => {
        let cleanedEmail = newEmail.toLowerCase();

        // exception cause one mail was created with an upper letter
        if (cleanedEmail === "i.seridi@bnvce.fr") {
            cleanedEmail = "I.seridi@bnvce.fr"
        }

        setValues({ ...values, email: cleanedEmail });
    }

    return (
        <div style={{ backgroundColor: secondaryColor, minHeight: '100vh' }}>
            <div className="register">
                {toast !== null && <Toast text={toast.text} severity={toast.severity} setToast={setToast} />}
                <Header />
                {success && <h1>LOGGED</h1>}
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <div className="form" style={{ borderColor: color }}>
                    <div className="form-block">
                        <p className="label">Email</p>
                        <input style={{ borderColor: color, backgroundColor: secondaryColor }} value={values.email} onChange={e => updateEmail(e.target.value)} type="email" id="email" name="email" placeholder="Email" />
                    </div>
                    <div className="form-block">
                        <p className="label">Mot de passe</p>
                        <input style={{ borderColor: color, backgroundColor: secondaryColor }} onChange={e => { setValues({ ...values, password: e.target.value }) }} type="password" id="password" name="password" placeholder="Mot de passe" />
                    </div>
                    <input className="submit" style={{ backgroundColor: color }} type="button" value="Se connecter" onClick={handleSubmit} />
                </div>
            </div>
        </div>
    );
}