function getArrayMaxValue(array) {
    if (array.length === 0) {
        return null;
    }

    let maxValue = array[0];

    for (let i = 1; i < array.length; i++) {
        if (array[i] > maxValue) {
            maxValue = array[i];
        }
    }

    return maxValue;
}

function findNextMultiple(value, multiple) {
    return Math.ceil(value / multiple) * multiple;
}

function sumArray(numbers) {
    let sum = 0;
    for (let i = 0; i < numbers.length; i++) {
        sum += numbers[i];
    }
    return sum;
}

module.exports = {
    getArrayMaxValue,
    findNextMultiple,
    sumArray,
};
