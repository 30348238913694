import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { useContext, useEffect, useState } from 'react';
import { getUser, storeFeatureUsage, updateLead } from '../api/axios';
import { FamilySituations, LeadStatus } from '../context/enums';

import React from 'react';
import AuthContext from '../context/AuthProvider';
import '../style/Lead.css';

function InfoCol(props) {

    const { id, label, value, champ, isFamilies, updateCallback, userId, secondaryColor } = props;
    const [newValue, setNewValue] = useState(value);

    const updateChamp = () => {
        const json = JSON.stringify({ champ: champ, value: newValue, user_id: userId });
        updateCallback(newValue);
        updateLead(id, json);
    }

    return (
        <div className='infoCol'>
            <p className='info-label'>{label}</p>

            {isFamilies === true /*drop down list for families */
                ? <select
                    name="families-select"
                    id="families-select"
                    onChange={(e) => setNewValue(e.target.value)}
                    onBlur={updateChamp}
                    value={newValue}
                    style={{
                        color: newValue ? "black" : "#b2b6ba",
                        fontWeight: newValue ? "700" : "400",
                        backgroundColor: secondaryColor,
                        maxWidth: "180px",
                        fontSize: newValue ? "18px" : "16px",
                        border: "none",
                    }}>
                    <option key={"empty"} value="">{"Inconnue"}</option>
                    {Object.keys(FamilySituations).map((key) => (
                        <option key={key} value={key}>
                            {FamilySituations[key]}
                        </option>
                    ))}
                    {!Object.keys(FamilySituations).includes(newValue)
                        ? <option key={"other__value"} value={newValue}>{newValue}</option>
                        : null}
                </select>
                : <div className='update-col'>
                    <input id="update-value" type='text' placeholder={label}
                        defaultValue={value} onBlur={updateChamp}
                        onChange={(e) => setNewValue(e.target.value)}
                    />
                </div>}

        </div>
    );
}

function Lead(props) {

    const {
        id,
        firstname,
        lastname,
        email,
        phone_number_concatenated,
        zipcode,
        year_of_birth,
        housing_status,
        commentaire,
        statut,
        income_tax,
        assigned_to,
        profession,
        family,
        legal_status,
        adresse,
        enfant,
        city,
        departement,
        prospect_company_name,
        prospect_company_industry,
        region,
    } = props.lead;
    const { handleShowExternalTools, showExternalToolsButton, setExternalToolText, setConfirmation } = props;

    const { auth, color, secondaryColor } = useContext(AuthContext);

    const [agent, setAgent] = useState(null);
    const [focusName, setFocusName] = useState(false);

    const [newLastname, setNewLastname] = useState(lastname);
    const [newFirstname, setNewFirstname] = useState(firstname);
    const [newComment, setNewComment] = useState(commentaire);
    const [newEmail, setNewEmail] = useState(email);
    const [newPhone, setNewPhone] = useState(phone_number_concatenated);
    const [newZipcode, setNewZipcode] = useState(zipcode);
    const [newBirthYear, setNewBirthYear] = useState(year_of_birth);
    const [newHousingStatus, setNewHousingStatus] = useState(housing_status);
    const [newStatut, setNewStatut] = useState(statut);
    const [newIncomeTax, setNewIncomeTax] = useState(income_tax);
    const [newProfession, setNewProfession] = useState(profession);
    const [newFamily, setNewFamily] = useState(family);
    const [newLegalStatus, setNewLegalStatus] = useState(legal_status);
    const [newAdress, setNewAdress] = useState(adresse);
    const [newChildren, setNewChildren] = useState(enfant);
    const [newCity, setNewCity] = useState(city);
    const [newDepartement, setNewDepartement] = useState(departement);
    const [newCompanyName, setNewCompanyName] = useState(prospect_company_name);
    const [newCompanyIndustry, setNewCompanyIndustry] = useState(prospect_company_industry);
    const [newRegion, setNewRegion] = useState(region);

    useEffect(() => {
        if (assigned_to) {
            getUser(assigned_to).then((res) => {
                if (res?.data.newFirstname && res?.data.newLastname) {
                    setAgent(res?.data.newFirstname + " " + res?.data.newLastname);
                } else {
                    setAgent(null);
                }
            })
        }
    }, [assigned_to]);

    const canTransferToSalesman = () => {
        return newStatut === LeadStatus["RDV_PRIS"] || newStatut === LeadStatus["RDV_HAPPENED"] || newStatut === LeadStatus["RDV_MISSED"] || newStatut === LeadStatus["SIGNE"];
    }

    const updateComment = () => {
        const json = JSON.stringify({ champ: 'commentaire', value: newComment, user_id: auth.id });
        updateLead(id, json);
    }

    const updateStatut = (e) => {
        setNewStatut(e.target.value);
        const json = JSON.stringify({ champ: 'statut', value: e.target.value, user_id: auth.id });
        updateLead(id, json);
    }

    const updateLastname = () => {
        const json = JSON.stringify({ champ: 'lastname', value: newLastname, user_id: auth.id });
        updateLead(id, json);
    }

    const updateFirstname = () => {
        const json = JSON.stringify({ champ: 'firstname', value: newFirstname, user_id: auth.id });
        updateLead(id, json);
    }

    const handleBlur = () => {
        updateLastname();
        updateFirstname();
        setFocusName(false);
    }

    const getLeadText = (addComment) => { // todo(3) ajouter ville etc ici
        let text = `Noms: ${newFirstname + " " + newLastname}\n`;
        if (newEmail) {
            text += `Email: ${newEmail}\n`;
        }
        if (newPhone) {
            let phoneNb = newPhone.length < 10 ? "0" + newPhone : newPhone
            text += `Téléphone: ${phoneNb}\n`;
        }
        text += "\n";
        if (newHousingStatus) {
            text += `Statut de résidence: ${newHousingStatus}\n`;
        }
        if (newIncomeTax) {
            text += `Imposition: ${newIncomeTax}\n`;
        }
        if (newBirthYear) {
            text += `Année de naissance: ${newBirthYear}\n`;
        }
        if (newZipcode) {
            text += `Code postal: ${newZipcode}\n`;
        }
        if (newProfession) {
            text += `Profession: ${newProfession}\n`;
        }
        if (newFamily) {
            text += `Situation familiale: ${newFamily}\n`;
        }
        if (newLegalStatus) {
            text += `Statuts juridique: ${newLegalStatus}\n`;
        }
        if (newAdress) {
            text += `Addresse: ${newAdress}\n`;
        }
        if (newChildren) {
            text += `Enfant ?: ${newChildren}\n`;
        }
        if (addComment && newComment) {
            text += `\n\nCommentaire: ${newComment}\n`;
        }

        return text;
    }

    const handleCopy = () => {
        storeFeatureUsage(auth.id, "copypaste_lead", JSON.stringify({ leadId: id })); // todo(3) retirer si trop appelllé en db
        navigator.clipboard.writeText(getLeadText(true))
            .catch((error) => {
                console.error('Failed to copy text: ', error);
            });
    };

    const handleExternalToolClick = () => {
        storeFeatureUsage(auth.id, "360_open", JSON.stringify({ leadId: id }));
        setExternalToolText(getLeadText(false));
        handleShowExternalTools();
    };

    const handleTransferToSalesman = () => {
        setConfirmation({
            "text": "Transférez ce lead à un vendeur.",
            "showYesButton": false,
            "showNoButton": false,
            "specialPopup": { type: "transfer2Salesman", leadId: id, companyId: auth.company_id },
            "confirmCallback": (userId) => {
                storeFeatureUsage(auth.id, "transfer2salesman", JSON.stringify({ leadId: id, userId }));
            }
        });
    };

    const getBasicLocalisationElement = () => {
        if (city) {
            return <InfoCol label="Ville" id={id} champ="city" value={newCity} updateCallback={setNewCity} userId={auth.id} secondaryColor={secondaryColor} />;
        } else if (zipcode) {
            return <InfoCol label="Code postal" id={id} champ="zipcode" value={newZipcode} updateCallback={setNewZipcode} userId={auth.id} secondaryColor={secondaryColor} />;
        } else {
            return <InfoCol label="Code postal" id={id} champ="zipcode" value={newZipcode} updateCallback={setNewZipcode} userId={auth.id} secondaryColor={secondaryColor} />;
        }
    }

    if (!auth) {
        return null;
    }

    return (
        <div className="lead">
            {!focusName && <h2 className="names" onClick={() => setFocusName(true)} style={{ color }}>{newFirstname + " " + newLastname}</h2>}
            {focusName && <div className='input-names'>
                <input className='input-name' type='text' value={newFirstname} onBlur={handleBlur} onChange={(e) => setNewFirstname(e.target.value)} />
                <input className='input-name' type='text' value={newLastname} onBlur={handleBlur} onChange={(e) => setNewLastname(e.target.value)} />
            </div>}
            <div className="row-1">
                <InfoCol label="Email" id={id} champ="email" value={newEmail} updateCallback={setNewEmail} userId={auth.id} secondaryColor={secondaryColor} />
                <InfoCol label="Téléphone" id={id} champ="phone_number_concatenated" value={newPhone.length < 10 ? "0" + newPhone : newPhone} updateCallback={setNewPhone} userId={auth.id} secondaryColor={secondaryColor} />
                <InfoCol label="Entreprise" id={id} champ="company_name" value={newCompanyName} updateCallback={setNewCompanyName} userId={auth.id} secondaryColor={secondaryColor} />
            </div>
            <div className="row-1">
                <InfoCol label="Année de naissance" id={id} champ="year_of_birth" value={newBirthYear} updateCallback={setNewBirthYear} userId={auth.id} secondaryColor={secondaryColor} />
                <InfoCol label="Profession" id={id} champ="profession" value={newProfession} updateCallback={setNewProfession} userId={auth.id} secondaryColor={secondaryColor} />
                <InfoCol label="Statuts juridique" id={id} champ="legal_status" value={newLegalStatus} updateCallback={setNewLegalStatus} userId={auth.id} secondaryColor={secondaryColor} />
            </div>
            <div className="row-1">
                {getBasicLocalisationElement()}
                <InfoCol label="Addresse" id={id} champ="adresse" value={newAdress} updateCallback={setNewAdress} userId={auth.id} secondaryColor={secondaryColor} />
                <InfoCol label="Departement" id={id} champ="departement" value={newDepartement} updateCallback={setNewDepartement} userId={auth.id} secondaryColor={secondaryColor} />
            </div>
            <div className="row-1">
                {/* <InfoCol label="Domaine de l'entreprise" id={id} champ="company_indudstry" value={newCompanyIndustry} updateCallback={setNewCompanyIndustry} userId={auth.id} secondaryColor={secondaryColor} /> */}
                <InfoCol label="Région" id={id} champ="region" value={newRegion} updateCallback={setNewRegion} userId={auth.id} secondaryColor={secondaryColor} />
                <InfoCol label="Situation familiale" id={id} champ="family" value={newFamily} isFamilies updateCallback={setNewFamily} userId={auth.id} secondaryColor={secondaryColor} />
                <InfoCol label="Statut de résidence" id={id} champ="housing_status" value={newHousingStatus} updateCallback={setNewHousingStatus} userId={auth.id} secondaryColor={secondaryColor} />
            </div>
            <div className="row-1">
                <InfoCol label="Imposition" id={id} champ="income_tax" value={newIncomeTax} updateCallback={setNewIncomeTax} userId={auth.id} secondaryColor={secondaryColor} />
                <InfoCol label="Enfant ?" id={id} champ="enfant" value={newChildren} updateCallback={setNewChildren} userId={auth.id} secondaryColor={secondaryColor} />
                <div className='infoColButtons'>
                    <ContentCopyIcon
                        className="content-copy-icon"
                        onClick={handleCopy}
                        sx={{ cursor: 'pointer', color: color }} />
                    {showExternalToolsButton ? <AccountBalanceIcon
                        onClick={handleExternalToolClick}
                        sx={{ cursor: 'pointer', color: color }} /> : null}
                    {canTransferToSalesman() ? <TransferWithinAStationIcon
                        onClick={handleTransferToSalesman}
                        sx={{ cursor: 'pointer', color: color }} /> : null}
                </div>
            </div>
            <div className="commentaire">
                <p className='info-label'>Commentaire</p>
                <div className='com-col'>
                    <textarea id="com-value" style={{ color }} type='text' defaultValue={newComment} onBlur={updateComment} onChange={(e) => setNewComment(e.target.value)} />
                </div>
            </div>
            <div className='footer' style={{ backgroundColor: color }}>
                <div className='footer-agent'>
                    {agent && <p className='footer-label'>Agent: </p>}
                    {agent && <p className='footer-value'>{agent}</p>}
                </div>
                <div className='footer-status'>
                    <p className='footer-label'>Statut: </p>
                    <select className="dropdown-statut" defaultValue={newStatut ? newStatut : 'DEFAULT'}
                        onChange={updateStatut} style={{ backgroundColor: color }}>
                        <option value="DEFAULT" disabled>Choisir un statut</option>
                        {Object.values(LeadStatus).map((l, i) => {
                            return <option key={i} value={l}>{l}</option>
                        })
                        }
                    </select>
                </div>
            </div>
        </div>
    );
}

export default Lead;