import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { LeadStatus } from "../../../context/enums";
import { sumArray } from "../../../lib/tools/numbers";


export default function TableUsersUsage({ usersUsageData }) {
    if (!usersUsageData) {
        return null;
    }

    const sumWorkDone = sumArray(usersUsageData.map((row) => row.work_done));
    const sumRdvBooked = sumArray(usersUsageData.map((row) => row.rdv_booked));
    const sumRdvTaken = sumArray(usersUsageData.map((row) => row.rdv_taken));
    const sumSignings = sumArray(usersUsageData.map((row) => row.signings));

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell align="right">Leads travaillés</TableCell>
                        <TableCell align="right">{LeadStatus["RDV_PRIS"]}</TableCell>
                        <TableCell align="right">{LeadStatus["RDV_HAPPENED"]}</TableCell>
                        <TableCell align="right">{LeadStatus["SIGNE"]}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usersUsageData.map((row) => {
                        let displayName = row.fullname;
                        if (row.user_type === "Manager") {
                            displayName += " (M)"
                        }
                        return <TableRow key={displayName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{displayName}</TableCell>
                            <TableCell align="right">{row.work_done}</TableCell>
                            <TableCell align="right">{row.rdv_booked}</TableCell>
                            <TableCell align="right">{row.rdv_taken}</TableCell>
                            <TableCell align="right">{row.signings}</TableCell>
                        </TableRow>;
                    }).concat([
                        <TableRow key={"__sum__"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">Total</TableCell>
                            <TableCell align="right">{sumWorkDone}</TableCell>
                            <TableCell align="right">{sumRdvBooked}</TableCell>
                            <TableCell align="right">{sumRdvTaken}</TableCell>
                            <TableCell align="right">{sumSignings}</TableCell>
                        </TableRow>
                    ])}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
