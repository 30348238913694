import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';
import home from '../images/home.svg';
import logo from '../images/logo.svg';
import logo_mobile from '../images/logo_mobile.svg';
import people from '../images/people.svg';
import person from '../images/person.svg';
import '../style/App.css';

export default function Header() {

    const { auth, setAuth, color, logoURL } = useContext(AuthContext);
    const [floating, setFloating] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleLogout = () => {
        localStorage.removeItem('user')
        setAuth({})
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getLogoSrc = () => {
        if (logoURL) {
            return logoURL;
        } else {
            return windowWidth > 500 ? logo : logo_mobile;
        }
    }

    const getMenuComponent = () => {
        const hasAdminPage = auth && auth.email && auth.user_type === "Manager";
        const hasFormationPage = auth && auth.has_formation_page === true;

        const menus = ["leads"];
        if (hasFormationPage) {
            menus.push("formation");
        }
        if (hasAdminPage) {
            menus.push("admin");
        }

        if (menus.length === 1) {
            return null;
        }

        const menusComponents = menus.map(menu => {
            if (menu === "leads") {
                return <Link key={menu} to={'/'}>
                    <img className='tabIcon' src={home} alt="Home" />
                </Link>;
            } else if (menu === "formation") {
                return <Link key={menu} to={'/formation'} style={{ textDecoration: 'none', color: 'black' }}>
                    <div className='tabIcon'>
                        <AutoStoriesIcon style={{ height: 40, width: 40, color: 'white' }} />
                    </div>
                </Link>
            } else if (menu === "admin") {
                return <Link key={menu} to={'/admin'}>
                    <img className='tabIcon' src={people} alt="People" />
                </Link>
            } else {
                return null;
            }
        });

        return <div className='tabs'>
            {menusComponents}
        </div>
    }

    return (
        <div className='Header' style={{ backgroundColor: color }}>
            <img className='logo' src={getLogoSrc()} alt="Logo" />
            {getMenuComponent()}

            {auth.email && <div className='profil' onClick={() => setFloating(!floating)}>
                <img className='tabIcon' src={person} alt="Person" />
                {windowWidth > 500 && <p className='username'>{auth.firstname}</p>}
                {floating && <div className='floating-menu'>
                    <p className='floating-label' onClick={handleLogout}>Se déconnecter</p>
                </div>}
            </div>}
        </div>
    );
}