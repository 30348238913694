import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getFeatureUsage } from "../../api/axios";
import { Metrics } from "../../context/enums";
import { date2frenchString, getFrenchDayName } from "../../lib/tools/datetime";
import { findNextMultiple, getArrayMaxValue } from "../../lib/tools/numbers";


export default function FeaturesUsage({ color, companies }) {
    // todo(1) finir probleme comment splitter par companies les lines

    const [companyId, setCompanyId] = useState("__undefined__");
    const [selectedMetric, setSelectedMetric] = useState("__undefined__");
    const [duration, setDuration] = useState(21);

    const [metricsPageOpenings, setMetricsPageOpenings] = useState([]);

    useEffect(() => {
        if (companyId === "__undefined__" || selectedMetric === "__undefined__") {
            return;
        }

        getFeatureUsage(selectedMetric, companyId, duration).then(data => {
            console.log('getFeatureUsage() result :>> ', data.data);
            setMetricsPageOpenings(data.data.map(row => { return { day: row.day, "usage_count": parseInt(row.usage_count) } }));
        });
    }, [companyId, selectedMetric, duration]);

    // todo(1) fonction en double
    const maxYAxis = findNextMultiple(Math.round(getArrayMaxValue(metricsPageOpenings.map(row => row.usage_count)) * 1.2 + 1), 4);

    function getDisplayDate(day) { // todo(1) fonction en double
        return getFrenchDayName(new Date(day)) + " " + date2frenchString(new Date(day));
    }

    // todo(1) bloc select company en double, ainsi que son state
    return (
        <Grid container spacing={2} style={{ padding: '10px' }}>
            <Grid item xs={12}>
                <h1 className="title" style={{ color }}>{"Utilisation des features"}</h1>
            </Grid>

            <Grid item xs={12} key="select_company">
                <InputLabel>Sélectionnez une entreprise:</InputLabel>
                <Select value={companyId} onChange={ev => setCompanyId(ev.target.value)}>
                    <MenuItem value="__undefined__">Veuillez choisir</MenuItem>;
                    {companies.filter((cpny) => cpny.nickname !== "la" && cpny.nickname !== "test").map((cpny, idx) => {
                        return <MenuItem key={cpny.id} value={cpny.id}>{cpny.name}</MenuItem>;
                    })}
                </Select>
                <br />
            </Grid>

            <Grid item xs={12} key="select_metric">
                <InputLabel>Sélectionnez une métrique:</InputLabel>
                <Select value={selectedMetric} onChange={ev => setSelectedMetric(ev.target.value)}>
                    <MenuItem value="__undefined__">Veuillez choisir</MenuItem>;
                    <MenuItem key="open_learning_page" value="open_learning_page">{Metrics["open_learning_page"]}</MenuItem>;
                    <MenuItem key="lead_view_show_filters" value="lead_view_show_filters">{Metrics["lead_view_show_filters"]}</MenuItem>;
                    <MenuItem key="copypaste_lead" value="copypaste_lead">{Metrics["copypaste_lead"]}</MenuItem>;
                    <MenuItem key="360_open" value="360_open">{Metrics["360_open"]}</MenuItem>;
                    <MenuItem key="transfer2salesman" value="transfer2salesman">{Metrics["transfer2salesman"]}</MenuItem>;
                    <MenuItem key="admin_metrics_open" value="admin_metrics_open">{Metrics["admin_metrics_open"]}</MenuItem>;
                    <MenuItem key="admin_assign_leads_to_collaborator" value="admin_assign_leads_to_collaborator">{Metrics["admin_assign_leads_to_collaborator"]}</MenuItem>;
                    <MenuItem key="admin_unassign_leads_from_collaborator" value="admin_unassign_leads_from_collaborator">{Metrics["admin_unassign_leads_from_collaborator"]}</MenuItem>;
                </Select>
                <br />
            </Grid>


            {companyId === "__undefined__" || selectedMetric === "__undefined__" ? null
                : <>
                    <div style={{ width: '100%', height: 460 }} key={`graph_${selectedMetric}_${companyId}`}>
                        <ResponsiveContainer>
                            <LineChart
                                data={metricsPageOpenings.map(row => {
                                    return { "Usage": row.usage_count, displayDate: getDisplayDate(row.day) }
                                })}
                                margin={{ top: 40, right: 60, left: 0, bottom: 40 }}>
                                <XAxis dataKey="displayDate" angle={45} height={150} textAnchor="start" />
                                <YAxis domain={[0, maxYAxis]} />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="Usage" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </>}
        </Grid>
    );
}
