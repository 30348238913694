import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getAllUsers, updatePassword } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";


// todo(3) ajouter un toast si ca marche
export default function UpdatePassword({ setToast }) {
    const [email, setEmail] = useState("__undefined__");
    const [password, setPassword] = useState("");
    const [users, setUsers] = useState([]);

    const { auth, color } = useContext(AuthContext);

    useEffect(() => {
        if (!auth) {
            return;
        }

        getAllUsers(auth.company_id).then(data => {
            setUsers(data.data);
        })
    }, [auth]);

    const reinitialize = () => {
        setPassword("");
    }

    const handleUpdatePassword = () => {
        if (!email || email === "__undefined__") {
            setToast({ text: "Echec, veuillez choisir un utilisateur.", severity: "error" });
            return;
        }

        console.log('password.length :>> ', password.length);
        if (!password || password.length <= 4) {
            setToast({ text: "Echec, mot de passe trop court.", severity: "error" });
            return;
        }

        updatePassword(email, password)
            .then(() => {
                reinitialize();
                setToast({ text: "Mot de passe mis à jour", severity: "success" });
            })
            .catch(err => {
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
                console.error(err);
            });
    };

    const customButtonStyle = {
        backgroundColor: color,
        color: 'white',
    };

    function getCollabdisplayString(collab) {
        let prefix = collab.office ? `[${collab.office.toUpperCase()}] ` : "";
        return `${prefix}${collab.lastname.toUpperCase()} ${collab.firstname}`;
    }

    return (
        <Grid container spacing={2} style={{ padding: '10px' }}>
            <Grid item xs={12}>
                <h3 className="title" style={{ color }}>Mettre à jour un mot de passe</h3>
            </Grid>
            <Grid key="accountType" item xs={12}>
                <Select value={email} onChange={ev => setEmail(ev.target.value)}>
                    <MenuItem value="__undefined__">Veuillez choisir un utilisateur</MenuItem>;
                    {users.map((user) => {
                        return <MenuItem key={user.email} value={user.email}>{getCollabdisplayString(user)}</MenuItem>;
                    })}
                </Select>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Mots de passe"
                    value={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                    fullWidth
                    variant="outlined" />
            </Grid>

            <Grid item xs={12}>
                <Button onClick={handleUpdatePassword} variant="contained" style={customButtonStyle}>
                    Mettre à jour
                </Button>
            </Grid>
        </Grid>
    );
}
