import React, { useEffect, useState } from "react";

import { Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { assignLeads, getLeadsCountByDepartement, getUserLeadsCount, storeFeatureUsage } from "../../api/axios";
import { Region } from "../../context/enums";
import addIcon from '../../images/add_icon.svg';
import rmIcon from '../../images/rm_icon.svg';
import { setNumericInput } from "../../lib/tools/strings";
import "../../style/Collaborators.css";


// todo(1) classe qui faisait parfois un peu trop d'appels d'API
export default function AssignLeads(props) {

    const { user, setToast, setConfirmation, list, color, auth } = props;

    const [userLeadsCount, setUserLeadsCount] = useState(null);
    const [regionLeadsCount, setRegionLeadsCount] = useState(0);

    const [countLeadsToAssign, setCountLeadsToAssign] = useState(100);
    const [region, setRegion] = useState('__all__');
    const [minAge, setMinAge] = useState('');
    const [maxAge, setMaxAge] = useState('');

    function updateUserLeadsCount(userId) {
        getUserLeadsCount(userId).then((res) => {
            setUserLeadsCount(res.data);
        });
    }

    useEffect(() => {
        setUserLeadsCount(null);
        updateUserLeadsCount(user.id);
    }, [user.id]);

    function updateRegionLeadsCount(updateRegion, listId, companyId) {
        const json = JSON.stringify({ region: updateRegion, company_id: companyId, list_id: listId });
        getLeadsCountByDepartement(json).then((res) => { setRegionLeadsCount(res.data) });
    }

    // en cas d'erreur sur ces api et les autres, on met pas à jour l'UI
    useEffect(() => {
        setRegionLeadsCount(0);
        updateRegionLeadsCount(region, list.id, auth.company_id);
    }, [region, user, list.id, auth]);

    const addLeadsToCollaboratorImpl = (region, nbleads) => {
        const json = JSON.stringify({
            user_id: user.id,
            region: region,
            len: nbleads,
            company_id: auth.company_id,
            list_id: list.id,
            min_age: minAge,
            max_age: maxAge,
        });

        assignLeads(json).then((res) => {
            const queriedLeadCount = res.data.queried;
            const assignedLeadCount = res.data.assined;

            if (assignedLeadCount === queriedLeadCount) {
                setToast({ text: "Leads attribués.", severity: "success" });
            } else {
                let message = `Seul ${assignedLeadCount} leads ont put être attribués, il en manquais ${queriedLeadCount - assignedLeadCount} pour remplir votre demande.`;
                setToast({ text: message, severity: "warning" });
            }

            updateUserLeadsCount(user.id);
            updateRegionLeadsCount(region, list.id, auth.company_id);
        }).catch((err) => {
            console.log("leadd aded with failure");
            setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
            console.error(err);
        });
    }

    const addLeadsToCollaborator = () => {
        if (regionLeadsCount === 0) {
            setToast({ text: `Il n'y a aucun lead disponible.`, severity: "error" });
            return;
        }

        if (regionLeadsCount < countLeadsToAssign) {
            setToast({ text: `Il n'y a que ${regionLeadsCount} leads disponibles.`, severity: "warning" });
        }
        setConfirmation({
            "text": "Etes-vous sur de vouloir attribuer des leads à cet utilisateur ?",
            "showYesButton": true,
            "showNoButton": true,
            "specialPopup": null,
            "confirmCallback": () => {
                addLeadsToCollaboratorImpl(region, countLeadsToAssign);
                storeFeatureUsage(auth.id, "admin_assign_leads_to_collaborator",
                    JSON.stringify({ countLeadsToAssign, userLeadsCount, region, minAge, maxAge, userId: user.id }));
            }
        });
    }

    return (
        <>
            {userLeadsCount !== null ? <Grid item xs={12} key="user_lead_count">
                <p>{user.firstname} a {userLeadsCount} leads.</p>
                <br />
            </Grid> : null}

            <Grid item xs={12} key="select_region">
                <InputLabel>Région préférée:</InputLabel>
                <Select value={region} onChange={ev => setRegion(ev.target.value)}>
                    <MenuItem value="__all__">Toute la France</MenuItem>;
                    {list.split_by_department && Object.values(Region).map((r, i) => {
                        return <MenuItem key={i} value={r.CodePostal}>{r.display}</MenuItem>;
                    })}
                </Select>
                <br />
                <br />
                <p>La région a {regionLeadsCount} leads disponibles.</p>
                <br />
            </Grid>

            <Grid item xs={12} key="select_min_age">
                <TextField
                    label="Age minimum désiré"
                    value={minAge}
                    onChange={(ev) => setNumericInput(ev, setMinAge)}
                    fullWidth
                    variant="outlined" />
            </Grid>

            <Grid item xs={12} key="select_max_age">
                <TextField
                    label="Age maximum désiré"
                    value={maxAge}
                    onChange={(ev) => setNumericInput(ev, setMaxAge)}
                    fullWidth
                    variant="outlined" />
            </Grid>

            <Grid item xs={12} key="select_count_leads_to_assign">
                <div className="buttons">
                    {countLeadsToAssign > 0 && <img className="icons" src={rmIcon} alt="rm Icon" onClick={() => setCountLeadsToAssign(countLeadsToAssign - 50)} style={{ cursor: 'pointer' }} />}
                    <input id="add-leads-btn" disabled={false} onClick={() => addLeadsToCollaborator()} type="button" value={"Ajouter " + countLeadsToAssign + " leads de cette liste"} style={{ backgroundColor: color, cursor: 'pointer' }} />
                    <img className="icons" src={addIcon} alt="Add leads Icon" onClick={() => setCountLeadsToAssign(countLeadsToAssign + 50)} style={{ cursor: 'pointer' }} />
                </div>
            </Grid>
        </>
    );
}
