function getFileExtension(filename) {
    return filename.split(".").pop();
}

function renameStringIntoFileName(originalString) {
    return originalString.replace(/ /g, "_");
}

function removeWhitespace(inputString) {
    return inputString.replace(/\s/g, '');
}

function extractPattern(inputString, regex) {
    const match = inputString.match(regex);
    return match ? match[0] : null;
}

function countWordsOfMinSize(inputString, minSize) {
    return inputString.split(/\s+/).filter(word => word.trim().length >= minSize).length;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function formatPhoneNumberWoParenthesis(str) {
    return str.replace(/[^0-9+]/g, '');
}

function getLastNCharacters(string, N) {
    if (string.length >= N) {
        return string.substring(string.length - N);
    } else {
        return string;
    }
}

function getPhoneNumberUniqueIdentifierPart(phone) {
    return getLastNCharacters(formatPhoneNumberWoParenthesis(phone), 9);
}

function keppOnlyNumericCharacters(inputString) {
    return inputString.replace(/\D/g, '');
}

function setDepartementInput(ev, callbackFunction) {
    const value = ev.target.value;
    if (!value) {
        callbackFunction("");
        return;
    }

    const processedValue = keppOnlyNumericCharacters(ev.target.value);
    if (!processedValue || isNaN(processedValue)) {
        callbackFunction("");
        return;
    }

    callbackFunction(`${processedValue}`);
}

function setNumericInput(ev, callbackFunction) {
    const value = ev.target.value;
    if (!value) {
        callbackFunction("");
        return;
    }

    const processedValue = parseInt(ev.target.value);
    if (!processedValue || isNaN(processedValue)) {
        callbackFunction("");
        return;
    }

    callbackFunction(`${processedValue}`);
}

module.exports = {
    getFileExtension,
    renameStringIntoFileName,
    removeWhitespace,
    extractPattern,
    countWordsOfMinSize,
    capitalizeFirstLetter,
    formatPhoneNumberWoParenthesis,
    getLastNCharacters,
    getPhoneNumberUniqueIdentifierPart,
    keppOnlyNumericCharacters,
    setDepartementInput,
    setNumericInput,
};
