import { useContext, useEffect, useRef, useState } from 'react';
import Toggle from 'react-toggle';
import { getAllCompanyLeadsByStatus, getAllCountUser, getCompanyLeads, getCompanyLeadsCount, getUserLeads, getUserLeadsCount, storeFeatureUsage } from './api/axios';
import Header from './component/header';
import Lead from './component/lead';
import AuthContext from './context/AuthProvider';
import { LeadStatus, UserType, Version } from './context/enums';

import './style/App.css';

import { useIntersection } from '@mantine/hooks';
import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import ExternalToolsSelection from './component/360/external_tools_selection';
import ConfirmNotification from './component/ux/confirmation';
import addIcon from './images/add_icon.svg';
import leftArrow from './images/left-arrow.svg';
import rightArrow from './images/right-arrow.svg';
import rmIcon from './images/rm_icon.svg';
import { setDepartementInput, setNumericInput } from './lib/tools/strings';
import { useDifferentLists } from './lib/useDifferentLists';

function MainPage() {

  const { auth, listsIds, color, secondaryColor } = useContext(AuthContext);

  const [confirmation, setConfirmation] = useState(null);

  const [input, setInput] = useState("");
  const [statut, setStatut] = useState("");
  const [departement, setDepartement] = useState("");
  const [minAge, setMinAge] = useState('');
  const [maxAge, setMaxAge] = useState('');
  const [unique, setUnique] = useState(false);
  const [index, setIndex] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [leads, setLeads] = useState([]);
  const [statutCount, setStatutCount] = useState([]);

  const [itemParPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [expandFilters, setExpandFilters] = useState(false);

  const [displayExternalTool, setDisplayExternalTool] = useState(false);
  const [externalToolText, setExternalToolText] = useState(null);

  const lastPostRef = useRef(null);
  const { ref, entry } = useIntersection({
    root: lastPostRef.current,
    threshold: 1
  });

  const [listIdIdx, getAccessibleListsIds, setListIdIdx, allLists] = useDifferentLists({ auth, accessibleListIds: listsIds, addAllLeadsList: true });

  const getListLeads = (listId, searchField, statusField, limit, offset, concatLead, departementField, minAgeField, maxAgeField) => {
    function processResult(res) {
      if (concatLead) {
        setLeads([...leads, ...res.data]);
      } else {
        setLeads(res.data);
      }
    }

    if (listId === "__all_company_leads__") {
      getCompanyLeads(auth.company_id, null, searchField, statusField, limit, offset, departementField, minAgeField, maxAgeField)
        .then(processResult);
    } else {
      getUserLeads(auth.id, listId, searchField, statusField, limit, offset, departementField, minAgeField, maxAgeField)
        .then(processResult);
    }
  }

  const fetchNextPage = () => {
    // getManagerLeads(auth.id, getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, (currentPage + 1) * itemParPage)
    //   .then((res) => { if (res.data) { setLeads([...leads, ...res.data]) } });
    getListLeads(getAccessibleListsIds()[listIdIdx],
      input,
      statut,
      itemParPage,
      (currentPage + 1) * itemParPage,
      true,
      departement,
      minAge,
      maxAge);
    setCurrentPage(currentPage + 1);
  }

  useEffect(() => {
    if (entry?.isIntersecting) fetchNextPage();
    // eslint-disable-next-line
  }, [entry]);

  const setup = () => {
    if (auth.user_type === UserType.MANAGER) {
      getAllCompanyLeadsByStatus(auth.company_id).then((res) => { setStatutCount(res.data) });
      getCompanyLeadsCount(auth.company_id).then((res) => setTotalCount(res.data));
    } else {
      getAllCountUser(auth.id).then((res) => { setStatutCount(res.data) });
      getUserLeadsCount(auth.id).then((res) => setTotalCount(res.data));
    }

    getListLeads(getAccessibleListsIds()[listIdIdx],
      input,
      statut,
      itemParPage,
      currentPage * itemParPage,
      false,
      departement,
      minAge,
      maxAge);
  }

  const handleMaxAgeFilterChange = (e) => {
    let maxAgeField = e.target.value;

    setCurrentPage(0);
    setLeads([]);
    setNumericInput(e, setMaxAge);
    getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, minAge, maxAgeField);
  }

  const handleMinAgeFilterChange = (e) => {
    let minAgeField = e.target.value;

    setCurrentPage(0);
    setLeads([]);
    setNumericInput(e, setMinAge);
    getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, minAgeField, maxAge);
  }

  const handleDepartementFilterChange = (e) => {
    let departementField = e.target.value;

    setCurrentPage(0);
    setLeads([]);
    setDepartementInput(e, setDepartement);
    getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departementField, minAge, maxAge);
  }

  const handleSearchChange = (e) => {
    let searchField = e.target.value;

    setCurrentPage(0);
    setLeads([]);
    setInput(searchField);
    getListLeads(getAccessibleListsIds()[listIdIdx], searchField, statut, itemParPage, 0, false, departement, minAge, maxAge);
  }

  const handleStatusChange = (e) => {
    let statusField = e.target.value;

    setCurrentPage(0);
    setLeads([]);
    setStatut(statusField);
    getListLeads(getAccessibleListsIds()[listIdIdx], input, statusField, itemParPage, 0, false, departement, minAge, maxAge);
  }

  const handleListIdChange = (newViewIdx) => {
    let listId = getAccessibleListsIds()[newViewIdx];

    setCurrentPage(0);
    setLeads([]);
    setListIdIdx(newViewIdx);
    getListLeads(listId, input, statut, itemParPage, 0, false, departement, minAge, maxAge);
  }

  useEffect(() => {
    setup();
  }, []);

  const isUptodateVersion = (auth) => {
    if (!auth || !auth.version) {
      return true;
    }

    return auth.version === Version;
  }

  useEffect(() => {
    let isUptoDate = isUptodateVersion(auth);

    if (!isUptoDate) {
      setConfirmation({
        "text": "La version de Lead Access que vous utilisez n'est plus à jour, veuillez rafraîchir la page.",
        "showYesButton": false,
        "showNoButton": false,
        "specialPopup": null,
        "confirmCallback": () => { }
      });
    }
  }, [auth]);

  const showExternalToolsButton = () => {
    return true
  }

  const handleShowExternalTools = () => {
    setDisplayExternalTool(true);
  }

  // todo(3) key ici devrait pas etre index, mais toujours lead id non ?????
  const getCentralComponent = () => {
    return <div>
      {unique && leads[index] &&
        <div key="controls">
          <div className='controls'>
            {index !== 0 ? <img className='ctrl-icon' src={leftArrow} alt='left arrow' onClick={() => setIndex(index - 1)} /> : <div></div>}
            {index !== leads.length ? <img className='ctrl-icon' src={rightArrow} alt='right arrow' onClick={() => setIndex(index + 1)} /> : <div></div>}
          </div>
          <Lead key={index} lead={leads[index]} setConfirmation={setConfirmation} handleShowExternalTools={handleShowExternalTools} showExternalToolsButton={showExternalToolsButton()} setExternalToolText={setExternalToolText} />
        </div>}
      {!unique && <div key="leads" className='leads'>
        {
          leads && leads?.map((lead) => {
            return <Lead key={lead.id} lead={lead} setConfirmation={setConfirmation} handleShowExternalTools={handleShowExternalTools} showExternalToolsButton={showExternalToolsButton()} setExternalToolText={setExternalToolText} />
          })
        }
      </div>
      }
      {leads.length >= (itemParPage / 2) ? <div key="ref" ref={ref}></div> : null}
    </div>;
  }

  const getTabsComponents = () => {
    return getAccessibleListsIds().map((listId, idx) => {
      let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];
      return <Tab key={listId} label={list.name} sx={{ color: '#090425', '&.Mui-selected': { color: '#090425' } }} />
    });
  }

  const getMainComponent = () => {
    if (!auth) {
      return null;
    }

    if (allLists === null) {
      return null;
    }

    if (getAccessibleListsIds().length < 2) {
      return getCentralComponent();
    }

    return <div>
      <Box style={{ paddingBottom: 30 }}>
        <Tabs
          value={listIdIdx}
          onChange={(event, newViewIdx) => {
            handleListIdChange(newViewIdx);
          }}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ "& .MuiTabs-indicator": { backgroundColor: '#090425' } }}
          aria-label="scrollable auto tabs example">
          {getTabsComponents()}
        </Tabs>
      </Box>
      {getCentralComponent()}
    </div>
  }

  const closeFilters = () => {
    setExpandFilters(false);
  }

  const openFilters = () => {
    storeFeatureUsage(auth.id, "lead_view_show_filters", null);
    setExpandFilters(true);
  }

  const getFiltersComponent = () => {
    let additionnalFilters = null;
    if (expandFilters) {
      additionnalFilters = <>
        <input className='filter-input' type='text' name='department'
          id='department' placeholder='Département'
          style={{ borderColor: color, width: '8em' }}
          value={departement}
          onChange={(e) => handleDepartementFilterChange(e)} />
        <input className='filter-input' type='text' name='min-age'
          id='min-age' placeholder='Age minimum'
          style={{ borderColor: color, width: '8em' }}
          value={minAge}
          onChange={(e) => handleMinAgeFilterChange(e)} />
        <input className='filter-input' type='text' name='max-age'
          id='max-age' placeholder='Age maximum'
          style={{ borderColor: color, width: '8em' }}
          value={maxAge}
          onChange={(e) => handleMaxAgeFilterChange(e)} />
      </>
    }

    return <div className='filter-names'>
      <h2 className='filter-tilte'>Filtres</h2>
      <select id="filter-statut" defaultValue={statut ? statut : ''} style={{ backgroundColor: color }}
        onChange={handleStatusChange}>
        <option value="" >Filtrer par statut</option>
        {Object.values(LeadStatus).map((l, i) => {
          return <option key={i} value={l}>{l}</option>
        })
        }
      </select>
      {additionnalFilters}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {expandFilters ? <img className="icons" src={rmIcon} onClick={closeFilters} />
          : <img className="icons" src={addIcon} onClick={openFilters} />}
      </div>
    </div>
  }

  return (
    <div style={{ backgroundColor: secondaryColor, minHeight: '100vh' }}>
      <div className="App">
        {displayExternalTool && <ExternalToolsSelection handleClose={() => setDisplayExternalTool(false)} text={externalToolText} />}
        {confirmation !== null && <ConfirmNotification text={confirmation.text}
          confirmCallback={confirmation.confirmCallback}
          setConfirmation={setConfirmation}
          color={color}
          showYesButton={confirmation.showYesButton}
          showNoButton={confirmation.showNoButton}
          specialPopup={confirmation.specialPopup} />}
        <Header />
        <div className='body'>
          <div className='stat-title'>
            <h1 style={{ color: color }}>{totalCount}</h1>
            <h1>{' leads'}</h1>
          </div>
          <div className='statut-list'>
            {statutCount?.map((s) => {
              const statut = s.statut ? s.statut : 'Sans statut';
              return (<div key={statut} className='statut-item' style={{ borderColor: color }}>
                <p className='statut-label'>{statut}</p>
                <p className='statut-value' style={{ color }}>{s.count_statut}</p>
              </div>);
            })}
          </div>
          <div className='filter_and_leads_container'>
            <div className='filter_and_card' style={{ backgroundColor: secondaryColor }}>
              <div className='filter'>
                <div className='filter-names'>
                  <h2 className='filter-tilte'>Rechercher</h2>
                  <input className='filter-input' type='text' name='search'
                    id='search' placeholder='Nom, prénom, téléphone, email...'
                    style={{ borderColor: color }}
                    value={input}
                    onChange={(e) => handleSearchChange(e)} />
                  <div className='filter-card'>
                    <Toggle
                      id='cheese-status'
                      defaultChecked={unique}
                      onChange={() => setUnique(!unique)} />
                    <p className='label-check'>One by one view</p>
                  </div>
                </div>
                {getFiltersComponent()}
              </div>
            </div>

            {getMainComponent()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;