import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { attributesSalesman, getCompanyUsers } from "../../../api/axios";


// todo(2)maybe add toast according to results ?
const Transfer2SalesmanNotification = ({ text, setConfirmation, companyId, leadId, color, confirmCallback }) => {
    const [collaborators, setCollaborators] = useState([]);
    const [selectedCollaborator, setSelectedCollaborator] = useState("__undefined__");

    useEffect(() => {
        getCompanyUsers(companyId).then(res => setCollaborators(res.data));
    }, [companyId]);

    const handleFinished = () => {
        setConfirmation(null);
    };

    function getCollabdisplayString(collab) {
        let prefix = collab.office ? `[${collab.office.toUpperCase()}] ` : "";
        return `${prefix}${collab.lastname.toUpperCase()} ${collab.firstname}`;
    }

    const handleAttributeClick = () => {
        if (selectedCollaborator === "__undefined__") {
            return;
        }

        attributesSalesman(leadId, selectedCollaborator).then(() => {
            confirmCallback(selectedCollaborator);
            handleFinished();
        });
    }

    const customButtonStyle = {
        backgroundColor: color,
        color: 'white',
    };

    return (
        <Dialog open={true}>
            <DialogTitle>
                <Typography variant="h6">{text}</Typography>
            </DialogTitle>
            <DialogContent>
                {/* <Typography>
                    {text}tt
                </Typography> */}
                <Grid item xs={12} key="select_worker">
                    <InputLabel>Sélectionnez un collaborateur:</InputLabel>
                    <Select value={selectedCollaborator} onChange={ev => setSelectedCollaborator(ev.target.value)}>
                        <MenuItem value="__undefined__">Veuillez choisir</MenuItem>;
                        {collaborators.map((collab) => {
                            return <MenuItem key={collab.id} value={collab.id}>{getCollabdisplayString(collab)}</MenuItem>;
                        })}
                    </Select>
                    <br />
                    <br />
                    <Grid item xs={12}>
                        <Button onClick={handleAttributeClick} variant="contained" style={customButtonStyle}>
                            Attribuer
                        </Button>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleFinished} style={customButtonStyle}>Fermer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default Transfer2SalesmanNotification;
