import { Button, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { addWorker, getCompanyUsers } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { UserType } from "../../context/enums";
import { capitalizeFirstLetter } from "../../lib/tools/strings";


// todo(3) ajouter un toast si ca marche
export default function AddWorker({ setToast }) {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [office, setOffice] = useState("__undefined__");
    const [userType, setUserType] = useState(UserType["USER"]);

    const [collaborators, setCollaborators] = useState([]);

    const { auth, color, secondaryColor } = useContext(AuthContext);

    const reinitialize = () => {
        setFirstname("");
        setLastname("");
        setEmail("");
        setPassword("");
        setOffice("__undefined__");
        setUserType(UserType["USER"]);
    }

    const handleAddWorker = () => {
        if (
            !firstname || firstname.length <= 2 ||
            !lastname || lastname.length <= 2 ||
            !email || email.length <= 2 ||
            !password || password.length <= 2
        ) {
            setToast({ text: "Echec, email ou mot de passe ou prénom ou nom imcomplet.", severity: "error" });
            return;
        }

        const cleanedOffice = office === "__undefined__" ? null : office;

        addWorker(firstname, lastname, email, password, auth.company_id, userType, cleanedOffice)
            .then(() => {
                reinitialize();
                setToast({ text: "Utilisateur créé.", severity: "success" });
            })
            .catch(err => {
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
                console.error(err)
            });
    };

    useEffect(() => {
        if (!auth) {
            return;
        }

        getCompanyUsers(auth.company_id).then(res => setCollaborators(res.data));
    }, [auth]);

    const getOfficeSelectorComponent = () => {
        if (!auth.company_offices) {
            return null;
        }

        const offices = JSON.parse(auth.company_offices);
        if (offices.length === 0) {
            return null;
        }

        return <Grid item xs={12}>
            <InputLabel>Bureau:</InputLabel>
            <Select value={office} onChange={ev => setOffice(ev.target.value)}>
                <MenuItem key="__undefined__" value="__undefined__">Choisissez un bureau</MenuItem>
                {offices.map((value) => {
                    return <MenuItem key={value} value={value}>{capitalizeFirstLetter(value)}</MenuItem>;
                })}
            </Select>
        </Grid>;
    }

    const getCollaboratorsTableComponent = () => {
        let companyHasOffices = auth && auth.company_offices;
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell>Prénom</TableCell>
                            <TableCell>Email</TableCell>
                            {companyHasOffices ? <TableCell>Bureau</TableCell> : null}
                            <TableCell>Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {collaborators.map((user) => {
                            return <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{user.lastname}</TableCell>
                                <TableCell>{user.firstname}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                {companyHasOffices ? <TableCell>{user.office}</TableCell> : null}
                                <TableCell>{user.user_type}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    const customButtonStyle = {
        backgroundColor: color,
        color: 'white',
    };

    if (!auth) {
        return null;
    }

    return (
        <Grid container spacing={2} style={{ padding: '10px' }}>
            <Grid item xs={12}>
                <h3 className="title" style={{ color }}>Ajouter un collaborateur</h3>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Prénom"
                    value={firstname}
                    onChange={(ev) => setFirstname(capitalizeFirstLetter(ev.target.value))}
                    fullWidth
                    variant="outlined" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Nom"
                    value={lastname}
                    onChange={(ev) => setLastname(capitalizeFirstLetter(ev.target.value))}
                    fullWidth
                    variant="outlined" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Email"
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value.toLowerCase())}
                    fullWidth
                    variant="outlined" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Mots de passe"
                    value={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                    fullWidth
                    variant="outlined" />
            </Grid>

            {getOfficeSelectorComponent()}

            <Grid item xs={12}>
                <InputLabel>Type d'utilisateur:</InputLabel>
                <Select value={userType} onChange={ev => setUserType(ev.target.value)}>
                    {Object.values(UserType).map((value) => {
                        return <MenuItem key={value} value={value}>{value}</MenuItem>;
                    })}
                </Select>
            </Grid>

            <Grid item xs={12}>
                <Button onClick={handleAddWorker} variant="contained" style={customButtonStyle}>
                    Ajouter
                </Button>
                <br />
                <br />
                <br />
            </Grid>

            <Grid item xs={12}>
                <h3 className="title" style={{ color }}>Liste des collaborateurs</h3>
            </Grid>

            <Grid item xs={12}>
                {getCollaboratorsTableComponent()}
                <br />
                <br />
                <br />
            </Grid>
        </Grid>
    );
}
