import { Button } from "@mui/material";
import React, { useContext } from "react";
import AuthContext from "../../context/AuthProvider";


const EmbededVideo = ({ handleClose, link }) => {
    const { auth, color, secondaryColor } = useContext(AuthContext);

    if (!auth || !color || !secondaryColor) {
        return null;
    }

    const customButtonStyle = {
        backgroundColor: color,
        color: 'white',
    };

    console.log('link :>> ', link);

    return (
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100vw',
            height: '100vh',
            backgroundColor: secondaryColor,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 999
        }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" style={{ ...customButtonStyle, flexShrink: 0 }} onClick={handleClose}>
                    Fermer
                </Button>
            </div>
            <iframe
                src={link}
                frameBorder="0"
                webkitallowfullscreen
                mozallowfullscreen="true"
                allowFullScreen
                style={{ flexGrow: 1 }} />
        </div>
    );
};

export default EmbededVideo;

